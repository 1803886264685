#root {
  height: 100%;
}

/* font classes begin */

.bold {
  font-weight: bold;
}

/* font classes end */

/* Tabler changes begin */
a:hover {
  text-decoration: none;
}

table .btn {
  margin-left: 4px;
}

.btn-link:hover {
  text-decoration: none !important;
}

.page-subtitle {
  font-size: 1rem;
  color: #6e7687;
  margin-left: 0.7rem;
}

/* Color chooser input height */
input[type=color] {
  height: 50px;
}

/* Show error messages under <Editor> components */
.invalid-feedback {
  display: block;
}

/* Add missing xs column media definition */
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs,
.col-xs-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}


@media (max-width: 575px) {
  .col-xs {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xs-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xs-1 {
    -ms-flex: 0 0 8.33333333%;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xs-2 {
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xs-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xs-4 {
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xs-5 {
    -ms-flex: 0 0 41.66666667%;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xs-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xs-7 {
    -ms-flex: 0 0 58.33333333%;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xs-8 {
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xs-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xs-10 {
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xs-11 {
    -ms-flex: 0 0 91.66666667%;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xs-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xs-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xs-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xs-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xs-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xs-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xs-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xs-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xs-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xs-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xs-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xs-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xs-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xs-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xs-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xs-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xs-0 {
    margin-left: 0;
  }
  .offset-xs-1 {
    margin-left: 8.33333333%;
  }
  .offset-xs-2 {
    margin-left: 16.66666667%;
  }
  .offset-xs-3 {
    margin-left: 25%;
  }
  .offset-xs-4 {
    margin-left: 33.33333333%;
  }
  .offset-xs-5 {
    margin-left: 41.66666667%;
  }
  .offset-xs-6 {
    margin-left: 50%;
  }
  .offset-xs-7 {
    margin-left: 58.33333333%;
  }
  .offset-xs-8 {
    margin-left: 66.66666667%;
  }
  .offset-xs-9 {
    margin-left: 75%;
  }
  .offset-xs-10 {
    margin-left: 83.33333333%;
  }
  .offset-xs-11 {
    margin-left: 91.66666667%;
  }


  /* Display XS controls */
  .d-xs-none {
    display: none !important;
  }
  .d-xs-inline {
    display: inline !important;
  }
  .d-xs-inline-block {
    display: inline-block !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-table {
    display: table !important;
  }
  .d-xs-table-row {
    display: table-row !important;
  }
  .d-xs-table-cell {
    display: table-cell !important;
  }
  .d-xs-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .d-xs-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  /* XS float controls */
  .float-xs-left {
    float: left !important;
  }
  .float-xs-right {
    float: right !important;
  }
  .float-xs-none {
    float: none !important;
  }

  /* XS Margin & padding  */
  .m-xs-0 {
    margin: 0 !important;
  }
  .mt-xs-0,
  .my-xs-0 {
    margin-top: 0 !important;
  }
  .mr-xs-0,
  .mx-xs-0 {
    margin-right: 0 !important;
  }
  .mb-xs-0,
  .my-xs-0 {
    margin-bottom: 0 !important;
  }
  .ml-xs-0,
  .mx-xs-0 {
    margin-left: 0 !important;
  }
  .m-xs-1 {
    margin: 0.25rem !important;
  }
  .mt-xs-1,
  .my-xs-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xs-1,
  .mx-xs-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xs-1,
  .my-xs-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xs-1,
  .mx-xs-1 {
    margin-left: 0.25rem !important;
  }
  .m-xs-2 {
    margin: 0.5rem !important;
  }
  .mt-xs-2,
  .my-xs-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xs-2,
  .mx-xs-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xs-2,
  .my-xs-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xs-2,
  .mx-xs-2 {
    margin-left: 0.5rem !important;
  }
  .m-xs-3 {
    margin: 0.75rem !important;
  }
  .mt-xs-3,
  .my-xs-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xs-3,
  .mx-xs-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xs-3,
  .my-xs-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xs-3,
  .mx-xs-3 {
    margin-left: 0.75rem !important;
  }
  .m-xs-4 {
    margin: 1rem !important;
  }
  .mt-xs-4,
  .my-xs-4 {
    margin-top: 1rem !important;
  }
  .mr-xs-4,
  .mx-xs-4 {
    margin-right: 1rem !important;
  }
  .mb-xs-4,
  .my-xs-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xs-4,
  .mx-xs-4 {
    margin-left: 1rem !important;
  }
  .m-xs-5 {
    margin: 1.5rem !important;
  }
  .mt-xs-5,
  .my-xs-5 {
    margin-top: 1.5rem !important;
  }
  .mr-xs-5,
  .mx-xs-5 {
    margin-right: 1.5rem !important;
  }
  .mb-xs-5,
  .my-xs-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xs-5,
  .mx-xs-5 {
    margin-left: 1.5rem !important;
  }
  .m-xs-6 {
    margin: 2rem !important;
  }
  .mt-xs-6,
  .my-xs-6 {
    margin-top: 2rem !important;
  }
  .mr-xs-6,
  .mx-xs-6 {
    margin-right: 2rem !important;
  }
  .mb-xs-6,
  .my-xs-6 {
    margin-bottom: 2rem !important;
  }
  .ml-xs-6,
  .mx-xs-6 {
    margin-left: 2rem !important;
  }
  .m-xs-7 {
    margin: 3rem !important;
  }
  .mt-xs-7,
  .my-xs-7 {
    margin-top: 3rem !important;
  }
  .mr-xs-7,
  .mx-xs-7 {
    margin-right: 3rem !important;
  }
  .mb-xs-7,
  .my-xs-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xs-7,
  .mx-xs-7 {
    margin-left: 3rem !important;
  }
  .m-xs-8 {
    margin: 4rem !important;
  }
  .mt-xs-8,
  .my-xs-8 {
    margin-top: 4rem !important;
  }
  .mr-xs-8,
  .mx-xs-8 {
    margin-right: 4rem !important;
  }
  .mb-xs-8,
  .my-xs-8 {
    margin-bottom: 4rem !important;
  }
  .ml-xs-8,
  .mx-xs-8 {
    margin-left: 4rem !important;
  }
  .m-xs-9 {
    margin: 6rem !important;
  }
  .mt-xs-9,
  .my-xs-9 {
    margin-top: 6rem !important;
  }
  .mr-xs-9,
  .mx-xs-9 {
    margin-right: 6rem !important;
  }
  .mb-xs-9,
  .my-xs-9 {
    margin-bottom: 6rem !important;
  }
  .ml-xs-9,
  .mx-xs-9 {
    margin-left: 6rem !important;
  }
  .p-xs-0 {
    padding: 0 !important;
  }
  .pt-xs-0,
  .py-xs-0 {
    padding-top: 0 !important;
  }
  .pr-xs-0,
  .px-xs-0 {
    padding-right: 0 !important;
  }
  .pb-xs-0,
  .py-xs-0 {
    padding-bottom: 0 !important;
  }
  .pl-xs-0,
  .px-xs-0 {
    padding-left: 0 !important;
  }
  .p-xs-1 {
    padding: 0.25rem !important;
  }
  .pt-xs-1,
  .py-xs-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xs-1,
  .px-xs-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xs-1,
  .py-xs-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xs-1,
  .px-xs-1 {
    padding-left: 0.25rem !important;
  }
  .p-xs-2 {
    padding: 0.5rem !important;
  }
  .pt-xs-2,
  .py-xs-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xs-2,
  .px-xs-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xs-2,
  .py-xs-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xs-2,
  .px-xs-2 {
    padding-left: 0.5rem !important;
  }
  .p-xs-3 {
    padding: 0.75rem !important;
  }
  .pt-xs-3,
  .py-xs-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xs-3,
  .px-xs-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xs-3,
  .py-xs-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xs-3,
  .px-xs-3 {
    padding-left: 0.75rem !important;
  }
  .p-xs-4 {
    padding: 1rem !important;
  }
  .pt-xs-4,
  .py-xs-4 {
    padding-top: 1rem !important;
  }
  .pr-xs-4,
  .px-xs-4 {
    padding-right: 1rem !important;
  }
  .pb-xs-4,
  .py-xs-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xs-4,
  .px-xs-4 {
    padding-left: 1rem !important;
  }
  .p-xs-5 {
    padding: 1.5rem !important;
  }
  .pt-xs-5,
  .py-xs-5 {
    padding-top: 1.5rem !important;
  }
  .pr-xs-5,
  .px-xs-5 {
    padding-right: 1.5rem !important;
  }
  .pb-xs-5,
  .py-xs-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xs-5,
  .px-xs-5 {
    padding-left: 1.5rem !important;
  }
  .p-xs-6 {
    padding: 2rem !important;
  }
  .pt-xs-6,
  .py-xs-6 {
    padding-top: 2rem !important;
  }
  .pr-xs-6,
  .px-xs-6 {
    padding-right: 2rem !important;
  }
  .pb-xs-6,
  .py-xs-6 {
    padding-bottom: 2rem !important;
  }
  .pl-xs-6,
  .px-xs-6 {
    padding-left: 2rem !important;
  }
  .p-xs-7 {
    padding: 3rem !important;
  }
  .pt-xs-7,
  .py-xs-7 {
    padding-top: 3rem !important;
  }
  .pr-xs-7,
  .px-xs-7 {
    padding-right: 3rem !important;
  }
  .pb-xs-7,
  .py-xs-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xs-7,
  .px-xs-7 {
    padding-left: 3rem !important;
  }
  .p-xs-8 {
    padding: 4rem !important;
  }
  .pt-xs-8,
  .py-xs-8 {
    padding-top: 4rem !important;
  }
  .pr-xs-8,
  .px-xs-8 {
    padding-right: 4rem !important;
  }
  .pb-xs-8,
  .py-xs-8 {
    padding-bottom: 4rem !important;
  }
  .pl-xs-8,
  .px-xs-8 {
    padding-left: 4rem !important;
  }
  .p-xs-9 {
    padding: 6rem !important;
  }
  .pt-xs-9,
  .py-xs-9 {
    padding-top: 6rem !important;
  }
  .pr-xs-9,
  .px-xs-9 {
    padding-right: 6rem !important;
  }
  .pb-xs-9,
  .py-xs-9 {
    padding-bottom: 6rem !important;
  }
  .pl-xs-9,
  .px-xs-9 {
    padding-left: 6rem !important;
  }
  .m-xs-auto {
    margin: auto !important;
  }
  .mt-xs-auto,
  .my-xs-auto {
    margin-top: auto !important;
  }
  .mr-xs-auto,
  .mx-xs-auto {
    margin-right: auto !important;
  }
  .mb-xs-auto,
  .my-xs-auto {
    margin-bottom: auto !important;
  }
  .ml-xs-auto,
  .mx-xs-auto {
    margin-left: auto !important;
  }

}

/* Tabler changes end */

/* React datepicker changes begin */
.react-datepicker-wrapper, 
.react-datepicker__input-container {
  width: 100% !important;
}

.react-datepicker-popper {
  z-index: 100;
}

.schedule-list-csdatepicker {
  width: 156px !important;
}

.schedule-list-page-options-btn-list {
  width: 100%;
}

/* React datepicker changes end */

/* Bring grid used as table in line with table */

.cs-grid-table-header {
  color: #9aa0ac;
  font-size: 15px;
  text-transform: uppercase;
}

.cs-grid-table-cell {
  padding: 12px 8px;
  border-bottom: 1px solid rgb(222, 226, 230);
  vertical-align: middle;
}

/* React confirm alert fixes begin */
.react-confirm-alert-overlay {
  z-index: 2000;
}
/* React confirm alert fixes end */

table td.cs-insight-data-table-cell {
  padding-top: 6px;
  padding-bottom: 6px;
}

 /* Shop class schedule business indicator */
 .cs-shop-classes-schedule-business-indicator {
  float: right;
  width: 75px;
 }

 /* Costasiella specific CSS */
 th.cs-finance-expenses-col-summary {
  width: 340px;
 }

 .finance-invoice-item-description textarea {
  min-height: 92px;
 }